import {
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import { getIsMembersAreaV2Context } from '../../editor/services/members-area-api';
import { Experiment } from '../../types';

import componentJson from './.component.json';

type DePromisify<T> = T extends Promise<infer U> ? U : T;

type AppManifest = DePromisify<ReturnType<GetAppManifestFn>>;

export const customizeFieldsEventId = 'manage-fields-clicked';

export const manageMemberPagesEventId = 'manage-member-pages-clicked';

export const createAppManifest = async (
  editorSDK: FlowEditorSDK,
  t: EditorScriptFlowAPI['translations']['t'],
  experiments: EditorScriptFlowAPI['experiments'],
): Promise<AppManifest> => {
  const isMembersAreaV2Context = await getIsMembersAreaV2Context(editorSDK);
  const shouldDisplayGeneralSettingsGfpp =
    !isMembersAreaV2Context &&
    experiments.enabled(Experiment.EnableGeneralSettings);

  return {
    controllersStageData: {
      [componentJson.id]: {
        default: {
          gfpp: {
            desktop: {
              mainAction2: shouldDisplayGeneralSettingsGfpp
                ? {
                    actionId: manageMemberPagesEventId,
                    label: t('app.gfpp.manage-member-pages'),
                  }
                : {
                    actionId: customizeFieldsEventId,
                    label: t('app.gfpp.customize-fields'),
                  },
              iconButtons: {},
            },
            mobile: {
              iconButtons: {},
            },
          },
        },
      },
    },
  };
};

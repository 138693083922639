import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestFn,
} from '@wix/yoshi-flow-editor';

import * as publicApi from './editor/services/public-api';
import { registerGfppEventListeners } from './editor/services/gfpp-event-listener';
import { createAppManifest } from './components/Profile/manifest';

let flowAPI: EditorScriptFlowAPI;

const editorReady: EditorReadyFn = async (editorSDK, _, __, _flowAPI) => {
  flowAPI = _flowAPI;
  registerGfppEventListeners(flowAPI, editorSDK);
};

const getAppManifest: GetAppManifestFn = async (_, editorSDK) => {
  const { translations, experiments } = flowAPI;
  return createAppManifest(editorSDK, translations.t, experiments);
};

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: { refreshApp: () => publicApi.refreshApp(flowAPI, editorSDK) },
});

export { editorReady, getAppManifest, _exports as exports };
